import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MuzeumWsiStojadlaPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum wsi Stojadła" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Obraz Matki Bożej Hallerowskiej</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Szczególną czcią wśród wiernych odwiedzających sanktuarium Matki Bożej
          Hallerowskiej w Mińsku Mazowieckim cieszy się wizerunek Matki Boskiej
          Anielskiej, zwany też Matką Boską Mińską, Matką Boską Hallerowską lub
          Panią Hallera, umieszczony w prawym ołtarzu bocznym. Został namalowany
          na płótnie przez Jana Czesława Moniuszkę, syna kompozytora Stanisława
          Moniuszki, w pierwszym dziesięcioleciu XX wieku. Najprawdopodobniej
          była to ostatnia praca tego artysty. Twórca nazwał go wizerunkiem
          Panny Maryi Dziewicy ulatującej z Aniołkami. Z obrazem tym związana
          jest postać gen. Józefa Hallera, który jako dowódca frontu
          północno-wschodniego armii polskiej w wojnie polsko-bolszewickiej,
          modlił się przed nim wraz ze swym sztabem o zwycięstwo w Bitwie
          Warszawskiej. To również właśnie przed tym wizerunkiem 17 sierpnia
          1920 roku, razem z nuncjuszem apostolskim Achillesem Ratti
          (późniejszym papieżem Piusem XI) generał Haller dziękował za
          odnie-sioną wiktorię i prosił o dalsze sukcesy Wojska Polskiego, a
          jako wotum obok obrazu zawiesił ryngraf. Później podczas niemieckiej
          okupacji do Matki Hallera tradycyjnie modlili się polscy żołnierze. W
          dniu 17 sierpnia 2000 roku obraz został ukoronowany przez Prymasa
          Polski kard. Józefa Glempa koroną, poświęconą rok wcześniej w katedrze
          warszawskiej przez Jana Pawła II.
        </p>
        <StyledGallery images={images} />
      </MonumentContentWrapper>
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/mb-hallerowska-nnmp/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/minsk-mazowiecki/mb-hallerowska-nnmp/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MuzeumWsiStojadlaPage
